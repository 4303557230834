import React, {useEffect, useState} from "react"
import './Admin.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function Admin(props) {
    const apiSrv = ""
    const [pageState, setPageState] = useState("LOADING")
    const [feedback, setFeedback] = useState([])
    const [title, setTitle] = useState("TITLE")

    let stringToColour = function(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }

    useEffect(() => {
        document.body.style.backgroundColor = null;
        document.body.style.color = null;
        document.body.style.fontSize = null;
        document.body.style.fontFamily = null;

        if(pageState == "LOADING") {
            fetch(apiSrv + '/api/post/list?pin=' + props.match.params.pin)
                .then(response => {
                    if(response.status == 401) {
                        throw Error("UNAUTHORIZED")
                    }
                    return response.json()
                })
                .then(data => {
                    console.log(data)
                    data = Array.from(data)
                    console.log(data)
                    data.sort((a, b) => a.time<b.time?1:-1);
                    setFeedback(data)
                    fetch(apiSrv + '/api/info')
                        .then(response => response.json())
                        .then(data => {
                            setTitle(data.title)
                            setPageState("DONE")
                        })
                        .catch((error) => {
                            setPageState("ERROR")
                            console.log(error)
                        });
                })
                .catch((error) => {
                    if(error == "Error: UNAUTHORIZED") {
                        setPageState("UNAUTHORIZED")
                    } else {
                        console.log(error)
                    }
                });
        }
    })

    if(pageState == "DONE") {
        return (
                <div className="feedback-list-container">
                    <h1>{title}</h1>
                    {feedback.length == 0 && "No feedback yet."}
                    {feedback.map((element, i) => {
                        let date = new Date(element.time * 1000)
                        let date_string = date.getDate() + "." + date.getMonth() + "." + date.getFullYear() + ", " + date.getHours() + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes()
                        return (
                            <div className="card" style={{marginBottom: 20}}>
                                <div className="card-header" style={{backgroundColor: stringToColour(element.fingerprint)}}>
                                    <div style={{float: "left"}}>{date_string}</div>
                                    <div style={{float: "right"}}>{element.fingerprint}</div>
                                </div>
                                <div className="card-body">
                                    {element.text}
                                </div>
                            </div>
                        )
                    })}
                </div>
        )
    } else if (pageState == "LOADING") {
        return (
            <>Loading...</>
        )
    } else if (pageState == "UNAUTHORIZED") {
        return (
            <>Wrong PIN. Please enter you PIN by going to URL /admin/YOURPIN</>
        )
    } else if (pageState == "ERROR") {
        return (
            <>Data couldn't be loaded.</>
        )
    }

}

export default Admin
