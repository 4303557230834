import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Overlay, Tooltip} from "react-bootstrap";
import Typewriter from "typewriter-effect";
import {MixedTags} from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import $ from 'jquery'
const fpPromise = FingerprintJS.load()

function Home(props) {
    const apiSrv = ""
    const target = useRef(null);
    const [initialLoadDone, setInitialLoadDone] = useState(false)
    const [showPrivateMsg, setShowPrivateMsg] = useState(false)
    const [title, setTitle] = useState("Loading...")
    const [text, setText] = useState("")
    const [fingerprint, setFingerprint] = useState("")
    const [settings, setSettings] = useState({
            pattern: /#/,
            duplicates: true,
            enforceWhitelist: true, // Wenn eigene Tags auch zugelassen werden sollen, kann das entfernt werden
            dropdown: {
                enabled: 0
            },
            whitelist: [{ value: "Loading..." }]
        })

    ;(async () => {
        const fp = await fpPromise
        const result = await fp.get()
        setFingerprint(result.visitorId)
    })()

    function save() {
        let ntext = text.replaceAll(/\[\[(.+?)\]\]/g, function(match) {
            let json = JSON.parse(match.replaceAll("[[", "").replaceAll("]]", ""))
            return json.prefix + "" + json.value
        })

        if(ntext.length < 10) {
            $('#tooShortMsg').fadeIn()
            setTimeout(function(){ $('#tooShortMsg').fadeOut(); }, 5000);
            return
        }

        let json = {
            "fingerprint": fingerprint,
            "text": ntext
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(json)
        };
        fetch(apiSrv + '/api/post', requestOptions)
            .then(response => response.json())
            .then(data => {
                document.getElementsByClassName("tagify__input")[0].innerHTML = ""
                $("#tooLongMsg").fadeOut()
                $('#saveMsg').fadeIn()
                setTimeout(function(){ $('#saveMsg').fadeOut(); }, 3000);
            })
            .catch((error) => {
                alert("An error occurred. Feedback couldn't be saved.")
                console.log(error)
            });;

    }

    const onChange = useCallback((e) => {
        let thisText = e.detail.value?e.detail.value:e.detail.textContent
        setText(thisText)
        if(thisText.length > 150 && $('#tooLongMsg').css("display") == "none") {
            $("#tooLongMsg").fadeIn()
        }
        if(thisText.length <= 150) {
            $("#tooLongMsg").fadeOut()
        }
    }, [])


    useEffect(() => {
        document.body.style.backgroundColor = "black";
        document.body.style.color = "white";
        document.body.style.fontSize = "1rem";
        document.body.style.fontFamily = "monospace";

        if(!initialLoadDone) {
            fetch(apiSrv + '/api/info')
                .then(response => response.json())
                .then(data => {
                    setTitle(data.title)
                    let tags = []
                    data.tags.forEach(tag => {
                        tags.push({value: tag})
                    })
                    let settingsCopy = {...settings}
                    settingsCopy.whitelist = tags
                    setSettings(settingsCopy)
                    setInitialLoadDone(true)
                })
                .catch((error) => {
                    setTitle("TITLE")
                    setInitialLoadDone(true)
                    console.log(error)
                });
        }

        $(".feedback-area").focusin(function (e) {
            if(e.currentTarget.nodeName == "TEXTAREA") {
                $("#saveBtn").focus()
            }
        })
    });

    if(initialLoadDone) {
        return (
            <>
                <div className="feedback-container">
                    <div className="typewriter" id="typewriter">
                        <p></p>
                    </div>
                    <span>
                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter.changeDelay(50)
                                typewriter
                                    .typeString('<strong>This is <img src="schnurrbart_white.png" style="height: 0.5rem; margin-right: 5px">Franky calling.</strong>')
                                    .pauseFor(1000)
                                    .typeString("<br>Why don\'t you tell me what you are thinking about  ")
                                    .typeString("<strong>" + title + "</strong>?")
                                    .pauseFor(1000)
                                    .pasteString('<br>Please post only one idea each time and feel free to use #hashtags.')
                                    .callFunction(() => {
                                        document.getElementsByClassName("Typewriter__cursor")[0].innerHTML = ""
                                        document.getElementById("inputDiv").style.opacity = 1
                                        document.getElementById("inputBtn").style.display = "block"
                                        document.getElementById("footer").style.display = "block"
                                    })
                                    .start()
                            }}
                        />
                    </span>
                    <div style={{opacity: 0, marginTop: 20}} id="inputDiv">
                        <MixedTags
                            autoFocus={true}
                            settings={settings}
                            className="feedback-area"
                            readOnly={false}
                            onChange={onChange}
                            onInput={onChange}
                            value={``}
                            placeholder={`Please post your valuable feedback here.`}
                        />
                    </div>
                    <div style={{marginTop: 20, display: "none"}} id="inputBtn">
                        <button className="btn btn-primary" onClick={save} id="saveBtn">Send Feedback</button>
                    </div>
                    <div style={{marginTop: 20, display: "none"}} id="saveMsg">
                        Thanks for your feedback! If you have anything else, feel free to drop another message.
                    </div>
                    <div style={{marginTop: 20, display: "none"}} id="tooLongMsg">
                        Your message is quite long. Remember to post only one thought at a time.
                    </div>
                    <div style={{marginTop: 20, display: "none"}} id="tooShortMsg">
                        But this is a short feedback :-) Please write a few words.
                    </div>
                    <div style={{textAlign: "center", marginTop: 80, display: "none"}} id="footer">
                        <div style={{fontSize: 16, cursor: "pointer", filter: "grayscale(1)"}} ref={target} onClick={() => setShowPrivateMsg(!showPrivateMsg)}>You are private 🔒</div>

                        <Overlay target={target.current} show={showPrivateMsg} placement="top">
                            {(props) => (
                                <Tooltip onClick={() => setShowPrivateMsg(!showPrivateMsg)} {...props}>
                                    We do not store or transmit names or other characteristics that reveal your identity. We only store a browser ID so that we can evaluate multiple responses from one person together. The browser ID is device-specific and not tied to your identity.
                                </Tooltip>
                            )}
                        </Overlay>

                        <div style={{fontSize: 16, marginTop: 20}}>Powered by <a href={"https://tamed.ai"}>TamedAI GmbH</a></div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                Loading...
            </>
        )
    }


}

export default Home;
