import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./Home";
import Admin from "./Admin";

const apiSrv = ""

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/admin/:pin" component={Admin} />
                <Route path="/admin" component={Admin} />
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
